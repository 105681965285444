export interface IEHRGraphiQLConfig {
  introspectionUrl?: string;
}

const config: IEHRGraphiQLConfig = {
  introspectionUrl: import.meta.env.IEHR_INTROSPECTION_URL || undefined,
};

export function getConfig(): IEHRGraphiQLConfig {
  return config;
}
