import { GraphiQLPlugin } from '@graphiql/react';
import { FetcherParams, SyncExecutionResult } from '@graphiql/toolkit';
import { MantineProvider, Title, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import { IEHRClient, ProfileResource, decodeBase64, encodeBase64, getDisplayString } from '@iehr/core';
import { Logo, IEHRProvider, SignInForm, useIEHRProfile } from '@iehr/react';
import '@iehr/react/styles.css';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime.js';
import { getConfig } from './config';

const HELP_TEXT = `# Welcome to iEHR GraphiQL
#
# iEHR is a modern medical-grade healthcare platform that helps 
# organizations deliver any healthcare product or service.
#
# Type queries into this side of the screen, and you will see intelligent
# typeaheads aware of the current GraphQL type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a "{" character. Lines that start
# with a # are ignored.
#
# Here is an example query to search for patients named Alice:
#
#   {
#     PatientList(name: "Alice") {
#       name {
#         given,
#         family
#       }
#     }
#   }
#
`;

const iehr = new IEHRClient({
  baseUrl: import.meta.env.IEHR_BASE_URL,
});

//https://mantine.dev/colors-generator/?color=9f8170
const theme = createTheme({
  colors: {
    'myColor':[
      "#fff2ec",
      "#f0e5e0",
      "#d8cbc2",
      "#c1aea3",
      "#ad9587",
      "#a18576",
      "#9c7d6c",
      "#896b5a",
      "#7c5e4e",
      "#6e503f"
    ],
  },
  primaryColor: 'myColor',
  primaryShade: 6,
  headings: {
    sizes: {
      h1: {
        fontSize: '1.125rem',
        fontWeight: '500',
        lineHeight: '2.0',
      },
    },
  },
  fontSizes: {
    xs: '0.6875rem',
    sm: '0.875rem',
    md: '0.875rem',
    lg: '1.0rem',
    xl: '1.125rem',
  },
});

async function fetcher(params: FetcherParams): Promise<SyncExecutionResult> {
  if (params.operationName === 'IntrospectionQuery') {
    const config = getConfig().introspectionUrl;
    if (config) {
      return (await fetch(config)).json();
    }
  }
  return iehr.graphql(params.query, params.operationName, params.variables);
}

const iehrPlugin: GraphiQLPlugin = {
  title: 'iEHR',
  icon: () => <Logo size={24} />,
  content: () => (
    <div>
      <p>iEHR GraphiQL</p>
      <p>User: {getDisplayString(iehr.getProfile() as ProfileResource)}</p>
      <p>Project: {iehr.getActiveLogin()?.project.display}</p>
      <p>
        <a href="#" onClick={() => iehr.signOut().then(() => window.location.reload())}>
          Sign out
        </a>
      </p>
    </div>
  ),
};

// Parse the search string to get url parameters.
const searchParams = new URLSearchParams(location.search);
const parameters: FetcherParams = {
  query: tryDecodeBase64(searchParams.get('query')),
  variables: tryDecodeBase64(searchParams.get('variables')),
  operationName: tryDecodeBase64(searchParams.get('operationName')),
};

function onEditQuery(newQuery: string): void {
  parameters.query = newQuery;
  updateURL();
}

function onEditVariables(newVariables: string): void {
  parameters.variables = newVariables;
  updateURL();
}

function onEditOperationName(newOperationName: string): void {
  parameters.operationName = newOperationName;
  updateURL();
}

function updateURL(): void {
  const newSearch = new URLSearchParams();
  newSearch.set('query', tryEncodeBase64(parameters.query));
  newSearch.set('variables', tryEncodeBase64(parameters.variables));
  newSearch.set('operationName', tryEncodeBase64(parameters.operationName));
  history.replaceState(null, '', `?${newSearch}`);
}

function tryEncodeBase64(value: string | null | undefined): string {
  if (!value) {
    return '';
  }
  try {
    return encodeBase64(value);
  } catch (_err) {
    return '';
  }
}

function tryDecodeBase64(value: string | null): string {
  if (!value) {
    return '';
  }
  try {
    return decodeBase64(value);
  } catch (_err) {
    return '';
  }
}

export function App(): JSX.Element {
  const profile = useIEHRProfile();
  return profile ? (
    <GraphiQL
      fetcher={fetcher}
      defaultQuery={HELP_TEXT}
      query={parameters.query || undefined}
      variables={parameters.variables || undefined}
      operationName={parameters.operationName || undefined}
      plugins={[iehrPlugin]}
      onEditQuery={onEditQuery}
      onEditVariables={onEditVariables}
      onEditOperationName={onEditOperationName}
    />
  ) : (
    <SignInForm googleClientId={import.meta.env.GOOGLE_CLIENT_ID} onSuccess={() => undefined}>
      <Logo size={32} fill={"#9F8170"}/>
      <Title>Welcome to iEHR GraphiQL</Title>
    </SignInForm>
  );
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <IEHRProvider iehr={iehr}>
      <MantineProvider theme={theme}>
        <App />
      </MantineProvider>
    </IEHRProvider>
  </StrictMode>
);
